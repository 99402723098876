export function getClassData(element){
    while (element !== null) {
        // Vérifie si l'élément courant a la classe 'data'
        if (element.classList.contains("data")) {
            return element;
        }
        element = element.parentElement;
    }
    return null;
}

export function getJsonFromElement(element){
    const json = {};

    // Trouver tous les descendants qui ont la classe 'entry'
    const entries = element.querySelectorAll('.entry');
    entries.forEach(entry => {
        const title = entry.getAttribute('title');
        let value;

        if (entry.tagName.toLowerCase() === 'input') {
            if (entry.type === 'text') {
                json[title] = entry.value.trim();
            } else if (entry.type === 'checkbox') {
                json[title] = entry.checked;
            }else {
                json[title] = entry.value.trim();
            }
        } else if (entry.tagName.toLowerCase() === 'ul') {
            const listResult = [];
            const listItems = entry.querySelectorAll('.item');
            listItems.forEach(listItem => {
                const listItemTitle = listItem.getAttribute('title');
                if (listItem.tagName.toLowerCase() === 'input') {
                    if (listItem.type === 'text') {
                        listResult.push(listItem.value.trim());
                    } else if (listItem.type === 'checkbox') {
                        if (listItem.checked){
                            listResult.push(listItem.title);
                        }
                    }else {
                        listResult.push(listItem.value.trim());
                    }
                }
            });
            json[title] = listResult;
        } else {
            json[title] = entry.textContent.trim();
        }
    });
    return json;
}