import {getClassData} from './utils.js';
import {getJsonFromElement} from './utils.js';
import {api} from './connection.mjs'

document.addEventListener('DOMContentLoaded', () => {
    const surveysList = document.getElementById('surveysList');

    const currentHostname = document.location.hostname
    let API_URL = `http://localhost:4000/surveys`;
    console.log('API_URL', API_URL)
    let charts = {}; // Object to hold chart instances

    const submitVote = async(element) => {
        const classData = getClassData(element);
        console.log(classData);
        const jsonFromSurvey = getJsonFromElement(classData);
        console.log(jsonFromSurvey);
        try{
            const response = await api(`/surveys/${element.id}/vote`, jsonFromSurvey);
            console.log(response);

        }catch(err){
            console.error(err)
            document.querySelector("#debug").innerHTML = err.toString()
        }
    }

    const loadSurvey = async (survey) => {
        const li = document.createElement('li');
        li.classList.add("data");
        const title = document.createElement('h3');
        title.textContent = `${survey.title}`;
        title.classList.add("entry");
        title.title = "title";
        li.appendChild(title);

        const responsesForm = document.createElement('ul');
        responsesForm.classList.add('responsesForm');
        responsesForm.classList.add('entry');
        responsesForm.title = 'choice';
        responsesForm.dataset.surveyId = survey._id;

        survey.choices.forEach((choice, index) => {
            const label = document.createElement('label');
            const nbVotes = `${survey.result.cmulated[choice]|| 0}` ;
            label.textContent = `${choice} - Votes: ${nbVotes}`;
            label.classList.add("form-check-label");

            const checkbox = document.createElement('input');
            checkbox.type = 'checkbox';
            checkbox.name = 'responseIndex';
            checkbox.title = choice;
            checkbox.value = index;
            checkbox.classList.add("form-check-input")
            checkbox.classList.add("me-1")
            checkbox.classList.add("item");

            label.prepend(checkbox);
            responsesForm.appendChild(label);
            responsesForm.appendChild(document.createElement('br'));
        });

        // Ajout de la liste de bouton par sondage
        const buttonForm = document.createElement('div');
        buttonForm.classList.add('d-flex');

        //Ajout du bouton pour voter
        const submitButton = document.createElement('button');
        submitButton.textContent = 'Vote';
        submitButton.id = survey._id;
        submitButton.classList.add("test");
        submitButton.classList.add("btn");
        submitButton.classList.add("btn-outline-success");
        submitButton.addEventListener('click', async (evt) => {
            await submitVote(evt.target);
            await loadSurveys();
        })
        buttonForm.appendChild(submitButton)


        // Ajout du bouton et du conteneur pour le camembert
        const resultsButton = document.createElement('button');
        resultsButton.textContent = 'Display results';
        resultsButton.classList.add("btn");
        resultsButton.classList.add("btn-outline-success");
        resultsButton.addEventListener('click', () => {
            console.log(survey._id);
            toggleResults(survey);
        });
        buttonForm.appendChild(resultsButton);
        responsesForm.appendChild(buttonForm);
        li.appendChild(responsesForm);

        const chartContainer = document.createElement('div');
        chartContainer.classList.add('chartContainer');
        chartContainer.style.display = 'none';
        chartContainer.innerHTML = `<canvas id="chart-${survey._id}"></canvas>`;
        li.appendChild(chartContainer);
        li.classList.add("list-group-item")
        surveysList.appendChild(li);
    }

    // Fonction pour charger les sondages
    const loadSurveys = async () => {
        try {
            const json = await api("surveys");
            console.log(json)
            surveysList.innerHTML = '';
            console.log('');
            json.forEach(survey => {
                console.log(survey);
                loadSurvey(survey)
            });
        } catch (error) {
            console.error('Erreur lors du chargement des sondages:', error);
        }
    };

    const toggleResults = (survey) => {
        const chartContainer = document.querySelector(`#chart-${survey._id}`).parentElement;
        if (chartContainer.style.display === 'none') {
            chartContainer.style.display = 'block';
            showResults(survey);
        } else {
            chartContainer.style.display = 'none';
            if (charts[survey._id]) {
                charts[survey._id].destroy();
                delete charts[survey._id];
            }
        }
    };

    const showResults = async (survey) => {
        try {
            const votes = []
            survey.choices.forEach(choice => {
                console.log(choice);
                votes.push(survey.result[choice] || 0);
            });
            const data = {
                labels: survey.choices,
                datasets: [{
                    label: 'Votes',
                    data: survey.result,
                    backgroundColor: [
                        'rgba(255, 99, 132, 0.2)',
                        'rgba(54, 162, 235, 0.2)',
                        'rgba(255, 206, 86, 0.2)',
                        'rgba(75, 192, 192, 0.2)',
                        'rgba(153, 102, 255, 0.2)',
                        'rgba(255, 159, 64, 0.2)'
                    ],
                    borderColor: [
                        'rgba(255, 99, 132, 1)',
                        'rgba(54, 162, 235, 1)',
                        'rgba(255, 206, 86, 1)',
                        'rgba(75, 192, 192, 1)',
                        'rgba(153, 102, 255, 1)',
                        'rgba(255, 159, 64, 1)'
                    ],
                    borderWidth: 1
                }]
            };

            const ctx = document.getElementById(`chart-${survey._id}`).getContext('2d');
            charts[survey._id] = new Chart(ctx, {
                type: 'pie',
                data: data,
                options: {
                    responsive: true
                }
            });
        } catch (error) {
            console.error('Erreur lors de la récupération des résultats:', error);
        }
    };

    // Charger les sondages au démarrage
    loadSurveys();
});
