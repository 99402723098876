const STORAGE_KEY = "PUBLIC_SURVEY_IDENTITY"

function generateCooperativeBrowserIdentity() {
    return new Date().toISOString().split("T")[0] + '_' + Math.floor(Math.random() * 1000000)
}

export function getCooperativeBrowserIdentity() {
    let id = localStorage.getItem(STORAGE_KEY)
    if (!id) {
        id = generateCooperativeBrowserIdentity()
        localStorage.setItem(STORAGE_KEY, id)
    }
    return {
        cooperative_browser: id
    }
}



export function getUserId() {
    const url = new URL(document.URL)
    const params = url.searchParams
    if (params.has("identity")) {
        return JSON.parse(params.get("identity"))
    } else if (params.has('anonymous')) {
        return null // {userId : "anonymous"}
    } else if (params.has('browserid')) {
        return getCooperativeBrowserIdentity()
    } else if (params.has('user')) {
        return {
            credentials: {
                user: params.get('user'),
                password: params.get('password')
            }
        }
    } else {
        return getCooperativeBrowserIdentity()
    }

}

export function getApiEndpoint() {
    const url = new URL(document.URL)
    if (url.searchParams.has("api_endpoint")) {
        const endpoint = url.searchParams.get("api_endpoint")
        return new URL(endpoint).toString()
    }
    if (url.hostname.includes("localhost")) {
        return `http://localhost:4000/`
    } else {
        return `${url.origin}/api/`
    }
}



/* 
*/
/**
 * ---
 * example usage :
 * - const result = await api(
 *    "/surveys/create", 
 *    {question: 'color ?', choices: ["red", "green"]}
 * )
 * ---
 * @param {String} route surveys API route you want to call
 * @param {Object} body surveys API payload
 * @returns surveys API response
 */
export async function api(route, body) {
    while (route.startsWith('/'))
        route = route.slice(1)
    const url = getApiEndpoint() + route

    let options = {}
    const userId = getUserId()
    if (userId)
        body = { ...(body || {}), userId }
    if (body) {
        options = {
            ...options,
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'content-type': 'application/json'
            }
        }
    }
    const response = await fetch(url, options)

    if (!response.ok) {
        const message = await response.text()
        throw `api returned ${response.statusText} : ${message}`
    }
    let result = null
    try {
        result = await response.json()
    } catch (err) {
        throw `result is not a json`
    }
    return result
}


